import React, {useState, useEffect} from 'react';
import api from './api'; // Import your API utility
import './BraceletRegistration.css'; // CSS file for styling
import {useParams} from 'react-router-dom';

const BraceletRegistration = ({user}) => {
    const {bracelet_id} = useParams();

    const [braceletId, setBraceletId] = useState(bracelet_id);
    const [ownedRooms, setOwnedRooms] = useState([]);

    const [bracelet, setBracelet] = useState(null);
    const [braceletRoom, setBraceletRoom] = useState(null);
    const [roomTitle, setRoomTitle] = useState('');
    const [roomName, setRoomName] = useState('');
    const [braceletRoomIsJoined, setBraceletRoomIsJoined] = useState(false);
    const [dataReady, setDataReady] = useState(false);

    console.log('~ braceletId initially', braceletId)

    useEffect(() => {
        const fetchBracelet = async () => {
            try {
                console.log('fetching bracelet of id ',braceletId);
                const response = await api.get('/api/bracelet/all', {
                    params: {bracelet_id: braceletId},
                });

                const {bracelet} = response.data;
                setBracelet(bracelet);
            } catch (error) {
		    setBracelet(null)
                console.error('Error fetching bracelet:', error);
            }
        };

        // Fetch rooms owned by the user
        const fetchBraceletRooms = async () => {
            try {
                const response = await api.get('/api/bracelet/room', {
                    params: {bracelet_id: braceletId, user_id: user.id},
                });
                console.log('fetching bracelet rooms', response.data);

                const {room, isJoined} = response.data;
                setDataReady(true)
                setBraceletRoom(room);
                setBraceletRoomIsJoined(isJoined);
            } catch (error) {
                setDataReady(false)
                setBraceletRoom(null);
                setBraceletRoomIsJoined(false);
                console.error('Error fetching bracelet rooms:', error);
            }
        };
        const fetchOwnedRooms = async () => {
            try {
                const response = await api.get('/api/room/list/self', {
                    params: {user_id: user.id},
                });
                setOwnedRooms(response.data.rooms);
            } catch (error) {
                console.error('Error fetching owned rooms:', error);
            }
        };

        fetchBraceletRooms();

        fetchBracelet();

        fetchOwnedRooms();

    }, [user.id, braceletId]);
    const handleCreateRoom = async () => {
        try {
            if (!roomTitle || !roomName) {
                alert('Please fill out both the room title and room name.');
                return; // Stop the function from continuing
            }
            const response = await api.post('/api/room/create', {
                bracelet_id: braceletId,
                owner_id: user.id,
                title: roomTitle,
                name: roomName,
            });
            alert('Room created successfully!');
            setBraceletRoom(response.data.room); // Update bracelet room state
        } catch (error) {
            console.error('Error creating room:', error);
            alert('Failed to create room.');
        }
    };

    const handleRegisterBracelet = async (roomId) => {
        try {
            const response = await api.post('/api/bracelet/register', {
                bracelet_id: braceletId,
                room_id: roomId,
            });
            setBraceletRoom(response.data)
            alert('Bracelet registered to room successfully!');
        } catch (error) {
            console.error('Error registering bracelet to room:', error);
            alert('Failed to register bracelet.');
        }
    };

    const handleJoinRoom = async () => {
        try {
            await api.post('/api/room/join', {
                bracelet_id: braceletId,
                user_id: user.id,
            });
            setBraceletRoomIsJoined(true);
            alert('Joined room successfully!');
        } catch (error) {
            setBraceletRoomIsJoined(false);
            console.error('Error joining room:', error);
            alert('Failed to join room.');
        }
    };
    if (!dataReady) {
        return (<div>Loading...</div>)
    } else {
        return (
            <div className="bracelet-registration-container">
                <h2>Bracelet Registration</h2>
                <input
                    type="text"
                    value={braceletId}
                    onChange={(e) => setBraceletId(e.target.value)}
                    placeholder="Enter your bracelet code"
                    required
                />

                {bracelet ? (braceletRoom ? (
                    braceletRoomIsJoined ? (
                        <div>
                            <h3>This bracelet is already joined!</h3>
                        </div>
                    ) : (
                        <div>
                            <h3>Join the Room Associated with Bracelet</h3>
                            <p>Your bracelet is associated with room: {braceletRoom.name}</p>
                            <button onClick={handleJoinRoom}>Join Room</button>
                        </div>

                    )

                ) : (
                    <div>
                        <div>
                            {(Array.isArray(ownedRooms) && ownedRooms.length > 0) && (
                                <div>
                                    <h3>Register Bracelet to Existing Room</h3>
                                    <ul>
                                        {ownedRooms.map((room) => (
                                            <li key={room.id}>
                                                <button onClick={() => handleRegisterBracelet(room.id)}>
                                                    Register Bracelet to {room.name}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )
                            }
                        </div>
                        <div>
                            <h3>Create a New Room</h3>
                            <input
                                type="text"
                                value={roomTitle}
                                onChange={(e) => setRoomTitle(e.target.value)}
                                placeholder="Enter room title"
                                required
                            />
                            <input
                                type="text"
                                value={roomName}
                                onChange={(e) => setRoomName(e.target.value)}
                                placeholder="Enter room name"
                                required
                            />
                            <button onClick={handleCreateRoom}>Create Room</button>
                        </div>

                    </div>
                )) : (
                    <div> This bracelet doesn't exist </div>
                )}

                <div className="rules-section">
                    <h3>Bracelet Registration Rules:</h3>
                    <ul>
                        <li>You can create a new room with your bracelet only once.</li>
                        <li>If you own a room, you can register your bracelet to that room.</li>
                        <li>
                            If the bracelet is already associated with a room, you can only
                            join that room.
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
};

export default BraceletRegistration;

// src/CakeSpoon.js
import React, { useEffect, useState } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';

function CakeSpoon({user}) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
    useEffect(() => {
    checkAuthStatus();
console.log("user object fetched ", user)
  }, []);

  const checkAuthStatus = async () => {
    try {
      await getCurrentUser();
      setIsAuthenticated(true);
    } catch (err) {
      setIsAuthenticated(false);
    }
  };
  
  return (
    <div>
      <h1>Cake Spoon Page</h1>
      {isAuthenticated ? (
        <p>You are authenticated!</p>
      ) : (
        <p>You are not authenticated.</p>
      )}
    </div>
  );
}

export default CakeSpoon;


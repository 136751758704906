import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';

// Function to get the ID token
const getJwt = async () => {
  try {
    const session = await fetchAuthSession(); // Assume fetchAuthSession is available globally or imported
    return session.tokens.accessToken.toString();
  } catch (error) {
    console.error('Error getting ID token', error);
    return null;
  }
};

// Create an Axios instance
const api = axios.create({
  baseURL: 'https://les3tress.com',
});

// Add a request interceptor to include the token in all requests
api.interceptors.request.use(async (config) => {
  const token = await getJwt();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  console.log("api...", config)
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default api;

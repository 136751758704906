// src/CakeSpoon.js
import React, { useEffect, useState } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';
import { Auth } from '@aws-amplify/auth';

import { fetchAuthSession } from 'aws-amplify/auth';

import axios from 'axios';

function SqlPage({user}) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [data, setData] = useState([]);
  const [whoami, setWhoami] = useState({});

    useEffect(() => {
    checkAuthStatus();

    getIdToken();

    axios.get('https://les3tress.com/get_room', {
	params: {
		user_id: 1234,
		room_id: 5678
	}
    })
      .then(response => {
        setData(response.data);
        console.log("success fetching", response.data);

      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
      });

	    	console.log("user", user)
  }, []);

  const checkAuthStatus = async () => {
    try {
      await getCurrentUser();
      setIsAuthenticated(true);
    } catch (err) {
      setIsAuthenticated(false);
    }
  };

// Function to get the ID token
const getIdToken = async () => {
  try {
	const session = await fetchAuthSession();

        console.log("access token", session.tokens.accessToken)
	const jwt = session.tokens.accessToken.toString()
        console.log("access token", session.tokens.accessToken.toString())
        console.log("id token", session)
        console.log('calling whoami')
        axios.get('https://les3tress.com/api/trash/whoami', {
            headers: {
                'Authorization': `Bearer ${jwt}`
            },
            params: {
            }
        })
        .then(response => {
          setWhoami(response.data);
          console.log("success fetching whoami", response.data);
        })
        .catch(error => {
          console.error('There was an error fetching whoami!', error);
        });

        return session.tokens.idToken;
  } catch (error) {
    console.error('Error getting ID token', error);
    return null;
  }
};

  return (
    <div>
      <h1>SQL Page</h1>
      <p> My very first network call</p>
      {isAuthenticated ? (
        <p>You are authenticated!</p>
      ) : (
        <p>You are not authenticated.</p>
      )}
    </div>
  );
}

export default SqlPage;


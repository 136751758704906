import { Amplify } from 'aws-amplify';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import config from './amplifyconfiguration.json';
import CakeSpoon from './CakeSpoon';
import Gotu from './Gotu';
import NotFound from './NotFound';
import Home from './Home';
import SqlPage from './SqlPage';
import Creation from './Creation';
import CreateRoom from './CreateRoom';
import RoomList from './RoomList';
import Room from './Room';
import ProfileUpdate from './ProfileUpdate';
import ProtectedRoute from './ProtectedRoute';
import BraceletRegistration from './BraceletRegistration';
import BraceletFoundry from './BraceletFoundry';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
Amplify.configure(config);

function App({ signOut, user }) {
  return (
    <Router>
      <Routes>
	<Route
          path="/gotu/:id"
          element={
            <ProtectedRoute user={user}>
              <Gotu user={user} />
            </ProtectedRoute>
          }
        />
	<Route
          path="/"
          element={
            <ProtectedRoute user={user}>
              <Home signOut={signOut} />
            </ProtectedRoute>
          }
        />

	<Route 
          path="/room_creation" 
          element={
            <ProtectedRoute user={user}>
              <CreateRoom user={user} />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/room_list" 
          element={
            <ProtectedRoute user={user}>
              <RoomList user={user} fetchType="all_rooms" />
            </ProtectedRoute>
          } 
        />

	<Route
	  path="/room_list/mine"
  	  element={
    	    <ProtectedRoute user={user}>
      	      <RoomList user={user} fetchType="my_rooms" />
    	    </ProtectedRoute>
  	}
	/>


	<Route
          path="/creation/:roomRefId"
          element={
            <ProtectedRoute user={user}>
              <Creation user={user}/>
            </ProtectedRoute>
          }
        />

        <Route 
          path="/room/:roomId" 
          element={
            <ProtectedRoute user={user}>
              <Room />
            </ProtectedRoute>
          } 
        />

	<Route
          path="/bracelet_registration/:bracelet_id"
          element={
            <ProtectedRoute user={user}>
              <BraceletRegistration user={user}/>
            </ProtectedRoute>
          } 
        />

	  <Route
          path="/bracelet_foundry"
          element={
            <ProtectedRoute user={user}>
              <BraceletFoundry user={user}/>
            </ProtectedRoute>
          }
        />

        <Route path="/cake/spoon" element={<CakeSpoon user={user} />} />
        <Route path="/sql" element={<SqlPage user={user} />} />
        <Route path="/profile-update" element={<ProfileUpdate user={user} />} />


        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default withAuthenticator(App, {signUpAttributes: ['email'], socialProviders: ['google']});

import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import api from './api'; // Adjust the path as necessary
import ProfileUpdate from './ProfileUpdate'
import OutfitUpdate from './OutfitUpdate'

const ProtectedRoute = ({ user, children }) => {
  const [loading, setLoading] = useState(true);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [isOutfitSelected, setIsOutfitSelected] = useState(false);

  const checkUserProfile = async () => {
    try {
      const response = await api.get('/api/user/profile/self', {
        params: { },
      });

      setIsProfileComplete(response.data && response.data.username);
	    console.log("profile complete", Boolean(response.data && response.data.username))// TODO(jake.lee) - Update so more than just username is selected.

      // Call to check if the user has selected their outfit
      const outfitResponse = await api.get('/api/outfit/self', {
        params: { },
      });

      setIsOutfitSelected(outfitResponse.data && outfitResponse.data.hat_id); // TODO(jake.lee) - Update so everything is selcted instead of just hat.
	    console.log("outfit complete", Boolean(outfitResponse.data && outfitResponse.data.username))
    } catch (error) {
      console.error('Error checking user profile or outfit:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkUserProfile();
  }, [user]);

  const handleProfileUpdateSuccess = () => {
    console.log("update recv protect")
    checkUserProfile();
  };

  if (loading) {
    return <div>Loading...</div>; // or a loading spinner
  } else if (!isProfileComplete) {
	return <ProfileUpdate finishCallback={handleProfileUpdateSuccess} />;
  } else if (!isOutfitSelected) {
	return <OutfitUpdate finishCallback={handleProfileUpdateSuccess} />;
  } else {
    return children;
  }
};

export default ProtectedRoute;


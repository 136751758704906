import React, { useState } from 'react';
import api from './api';
import Header from './Header'; // Import the Header component

const CreateRoom = () => {
  const [roomTitle, setRoomName] = useState('');

  const handleRoomNameChange = (e) => {
    setRoomName(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const roomData = {
      title: roomTitle,
    };

    api.post('/create_room', roomData)
      .then(response => {
        alert('Room created successfully!');
      })
      .catch(error => {
        console.error('There was an error creating the room!', error);
      });
  };

  return (
    <div>
      <Header title="Create a Room" /> {/* Include the Header */}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Room Name:</label>
          <input type="text" value={roomTitle} onChange={handleRoomNameChange} required />
        </div>
        <button type="submit">Create Room</button>
      </form>
    </div>
  );
};

export default CreateRoom;


import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from './api';
import { readAndCompressImage } from 'browser-image-resizer';
import './Creation.css'; // Import the CSS file
import Header from './Header'; // Import the Header component

function Creation({ user }) {
  const { roomRefId } = useParams();
  const [data, setData] = useState({});

  useEffect(() => {
    // Any side effects or data fetching would go here
  }, []);

  const config = {
    quality: 0.7,
    width: 2400,
    height: 1800
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    const fileInput = event.target.elements.image;
    const captionInput = event.target.elements.caption;
    const originalFile = fileInput.files[0];

    console.log('orig', originalFile);

    const resizedFile = await readAndCompressImage(originalFile, config);

    console.log('resized', resizedFile);

    formData.append('image', resizedFile);
    formData.append('description', captionInput.value);
    formData.append('room_id', roomRefId);

    try {
      const response = await api.post('/api/room/list/all', formData);
      alert("Succeeded");
      console.log('File uploaded successfully', response.data);
    } catch (error) {
      alert("failed");
      console.error('Error uploading file', error);
    }
  };

  return (
    <div className="creation-container">
        <Header title="Create Post" /> {/* Include the Header */}

      <form onSubmit={handleSubmit} className="creation-form">
        <input type="file" name="image" accept="image/*" className="form-input" />
        <input type="text" name="caption" placeholder="Caption" className="form-input" />
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
}

export default Creation;


import React, {useEffect, useState} from 'react';
import {getCurrentUser} from 'aws-amplify/auth';
import MiniMeComponent from './MiniMeComponent';
import './Home.css'; // Import the CSS file
import SpriteAnimation from './SpriteAnimation';

function Home({signOut}) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userId, setUserId] = useState(false);
    const [action, setAction] = useState(0);

    useEffect(() => {
        checkAuthStatus();
    }, []);

    const checkAuthStatus = async () => {
        try {
            const a = await getCurrentUser();
            setUserId(a.userId);
            console.log('User data:', a);
            setIsAuthenticated(true);
        } catch (err) {
            setIsAuthenticated(false);
        }
    };

    const handleChange = (event) => {
        setAction(Number(event.target.value));
    };

    const frameWidth = 80
    const frameHeight = 100
    const frameCount = 5
    const frameRate = 10

    return (
        <div className="home-container">
            <h1>Home Page</h1>
            <div style={{position: 'relative', width: frameWidth, height: frameHeight}}>
                <div style={{position: 'absolute', top: 0, left: 0}}>
                    <SpriteAnimation
                        bodySheet="https://amplify-reactgotu-dev-2589b-deployment.s3.us-east-2.amazonaws.com/outfits/human/girl_character.png"
                        hatSheet="https://amplify-reactgotu-dev-2589b-deployment.s3.us-east-2.amazonaws.com/outfits/human/hat/cowboyhat.png"
                        magicSheet="https://amplify-reactgotu-dev-2589b-deployment.s3.us-east-2.amazonaws.com/outfits/human/magic_foreground/magic_sprite.png"
                        pantsSheet="https://amplify-reactgotu-dev-2589b-deployment.s3.us-east-2.amazonaws.com/outfits/human/pants/ripped_jeans.png"
                        shoesSheet="https://amplify-reactgotu-dev-2589b-deployment.s3.us-east-2.amazonaws.com/outfits/human/shoes/sneakers.png"
                        sunglassesSheet="https://amplify-reactgotu-dev-2589b-deployment.s3.us-east-2.amazonaws.com/outfits/human/sunglasses/deal_with_it.png"
                        topSheet="https://amplify-reactgotu-dev-2589b-deployment.s3.us-east-2.amazonaws.com/outfits/human/top/pink_dress.png"
                        frameWidth={frameWidth}
                        frameHeight={frameHeight}
                        frameCount={frameCount}
                        frameRate={frameRate}
                        action={action}
                        loop={true}
                    />
                </div>
            </div>
            <select onChange={handleChange}>
                <option value="0">Action 0</option>
                <option value="1">Action 1</option>
                <option value="2">Action 2</option>
                <option value="3">Action 3</option>
                <option value="4">Action 4</option>
                <option value="5">Action 5</option>
            </select>

            {isAuthenticated ? (
                <div className="auth-section">
                    <h2 className="auth-status">You are authenticated!</h2>
                    <p>Welcome! You can now explore the features below.</p>
                    <div className="links-container">
                        <a href="/cake/spoon" className="btn">Cake Spoon</a>
                        <a href="/sql" className="btn">Go to SQL page</a>
                        <a href="/bracelet_foundry" className="btn">Bracelet Foundry</a>
                        <a href="/bracelet_registration/1" className="btn">Bracelet Registration</a>
                        <a href="/room_list" className="btn">Room List</a>
                        <a href="/room_list/mine" className="btn">My Room List</a>
                    </div>
                    <MiniMeComponent userId={userId}/>
                    <button onClick={signOut} className="sign-out-btn">Sign Out</button>
                </div>
            ) : (
                <h2 className="auth-status not-auth">You are NOT authenticated!</h2>
            )}
        </div>
    );
}

export default Home;


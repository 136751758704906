import React, { useEffect, useState } from 'react';
import api from './api'; // Import your API utility
import './OutfitUpdate.css'; // Import the CSS file

const OutfitUpdate = ({ finishCallback }) => {
  const [bodies, setBodies] = useState([]);
  const [hats, setHats] = useState([]);
  const [tops, setTops] = useState([]);
  const [pants, setPants] = useState([]);
  const [selectedBody, setSelectedBody] = useState(0);
  const [selectedHat, setSelectedHat] = useState(0);
  const [selectedTop, setSelectedTop] = useState(0);
  const [selectedPants, setSelectedPants] = useState(0);

  useEffect(() => {
    const fetchOutfits = async () => {
      try {
        const response = await api.get('/api/outfit/list/all');
        const { bodies, hats, tops, pants } = response.data;
        setBodies(bodies);
        setHats(hats);
        setTops(tops);
        setPants(pants);
      } catch (error) {
        console.error('Error fetching outfits:', error);
      }
    };

    fetchOutfits();
  }, []);

  const handleSubmit = async () => {
    console.log('bodies', bodies[selectedBody]);
    console.log('hats', hats[selectedHat]);
    console.log('tops', tops[selectedTop]);
    console.log('pants', pants[selectedPants]);

    api.post('/api/outfit/select', {
      body_id: bodies[selectedBody].id,
      hat_id: hats[selectedHat].id,
      top_id: tops[selectedTop].id,
      pants_id: pants[selectedPants].id
    })
      .then(response => {
        alert('Outfit updated successfully!');
        finishCallback();
      })
      .catch(error => {
        console.error('There was an error updating the outfit!', error);
        alert('Failed to update outfit.' + error);
      });
  };

  const handleNext = (type) => {
    if (type === 'body') {
      setSelectedBody((selectedBody + 1) % bodies.length);
    } else if (type === 'hat') {
      setSelectedHat((selectedHat + 1) % hats.length);
    } else if (type === 'top') {
      setSelectedTop((selectedTop + 1) % tops.length);
    } else if (type === 'pants') {
      setSelectedPants((selectedPants + 1) % pants.length);
    }
  };

  const handlePrev = (type) => {
    if (type === 'body') {
      setSelectedBody((selectedBody - 1 + bodies.length) % bodies.length);
    } else if (type === 'hat') {
      setSelectedHat((selectedHat - 1 + hats.length) % hats.length);
    } else if (type === 'top') {
      setSelectedTop((selectedTop - 1 + tops.length) % tops.length);
    } else if (type === 'pants') {
      setSelectedPants((selectedPants - 1 + pants.length) % pants.length);
    }
  };

  return (
    <div className="outfit-update-container">
      <div className="outfit-preview">
        <img src={bodies[selectedBody]?.image_url} alt="Selected Body" className="outfit-image" />
        <img src={pants[selectedPants]?.image_url} alt="Selected Pants" className="outfit-image" />
        <img src={tops[selectedTop]?.image_url} alt="Selected Top" className="outfit-image" />
        <img src={hats[selectedHat]?.image_url} alt="Selected Hat" className="outfit-image" />
      </div>

      <div className="outfit-selectors">
        <div className="outfit-category">
          <h3>Body</h3>
          <div className="outfit-description">
            <button className="outfit-nav-button" onClick={() => handlePrev('body')}>
              <img src="https://amplify-reactgotu-dev-2589b-deployment.s3.us-east-2.amazonaws.com/icons/left_arrow.png" alt="Previous" className="nav-arrow" />
            </button>
            <div className="outfit-details">
              <img src={bodies[selectedBody]?.image_url} alt="Selected Body" className="outfit-icon" />
              <p className="outfit-label">{bodies[selectedBody]?.description || 'Loading...'}</p>
            </div>
            <button className="outfit-nav-button" onClick={() => handleNext('body')}>
              <img src="https://amplify-reactgotu-dev-2589b-deployment.s3.us-east-2.amazonaws.com/icons/right_arrow.png" alt="Next" className="nav-arrow" />
            </button>
          </div>
        </div>

        <div className="outfit-category">
          <h3>Hat</h3>
          <div className="outfit-description">
            <button className="outfit-nav-button" onClick={() => handlePrev('hat')}>
              <img src="https://amplify-reactgotu-dev-2589b-deployment.s3.us-east-2.amazonaws.com/icons/left_arrow.png" alt="Previous" className="nav-arrow" />
            </button>
            <div className="outfit-details">
              <img src={hats[selectedHat]?.image_url} alt="Selected Hat" className="outfit-icon" />
              <p className="outfit-label">{hats[selectedHat]?.description || 'Loading...'}</p>
            </div>
            <button className="outfit-nav-button" onClick={() => handleNext('hat')}>
              <img src="https://amplify-reactgotu-dev-2589b-deployment.s3.us-east-2.amazonaws.com/icons/right_arrow.png" alt="Next" className="nav-arrow" />
            </button>
          </div>
        </div>

        <div className="outfit-category">
          <h3>Top</h3>
          <div className="outfit-description">
            <button className="outfit-nav-button" onClick={() => handlePrev('top')}>
              <img src="https://amplify-reactgotu-dev-2589b-deployment.s3.us-east-2.amazonaws.com/icons/left_arrow.png" alt="Previous" className="nav-arrow" />
            </button>
            <div className="outfit-details">
              <img src={tops[selectedTop]?.image_url} alt="Selected Top" className="outfit-icon" />
              <p className="outfit-label">{tops[selectedTop]?.description || 'Loading...'}</p>
            </div>
            <button className="outfit-nav-button" onClick={() => handleNext('top')}>
              <img src="https://amplify-reactgotu-dev-2589b-deployment.s3.us-east-2.amazonaws.com/icons/right_arrow.png" alt="Next" className="nav-arrow" />
            </button>
          </div>
        </div>

        <div className="outfit-category">
          <h3>Pants</h3>
          <div className="outfit-description">
            <button className="outfit-nav-button" onClick={() => handlePrev('pants')}>
              <img src="https://amplify-reactgotu-dev-2589b-deployment.s3.us-east-2.amazonaws.com/icons/left_arrow.png" alt="Previous" className="nav-arrow" />
            </button>
            <div className="outfit-details">
              <img src={pants[selectedPants]?.image_url} alt="Selected Pants" className="outfit-icon" />
              <p className="outfit-label">{pants[selectedPants]?.description || 'Loading...'}</p>
            </div>
            <button className="outfit-nav-button" onClick={() => handleNext('pants')}>
              <img src="https://amplify-reactgotu-dev-2589b-deployment.s3.us-east-2.amazonaws.com/icons/right_arrow.png" alt="Next" className="nav-arrow" />
            </button>
          </div>
        </div>
      </div>

      <button className="done-button" onClick={handleSubmit}>
        Done
      </button>
    </div>
  );
};

export default OutfitUpdate;


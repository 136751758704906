import React, { useState , useEffect} from 'react';
import api from './api';
import './ProfileUpdate.css'; // Import the CSS file
import Header from './Header'
import { readAndCompressImage } from 'browser-image-resizer';
import { useNavigate } from 'react-router-dom';

const ProfileUpdate = ({finishCallback}) => {
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [gender, setGender] = useState('');

  const navigate = useNavigate();

  const handleProfileImageChange = (e) => {
    setProfileImage(e.target.files[0]);
  };

  const config = {
    quality: 0.7,
    width: 2400,
    height: 1800
  };

  useEffect(() => {
    console.log("finishCallback in ProfileUpdate:", typeof finishCallback);
  }, [finishCallback]);

  const handleSubmit = async (e) => {
    e.preventDefault();
 
    const fileInput = e.target.elements.image;
    const resizedFile = await readAndCompressImage(profileImage, config);

    const formData = new FormData();
    formData.append('id', 'cognito-user-id'); // Replace with the actual Cognito user ID
    formData.append('username', username);
    formData.append('name', name);
    formData.append('image', resizedFile);
    formData.append('date_of_birth', dateOfBirth || null);
    formData.append('gender', gender);

    api.post('/api/user/create', formData)
      .then(response => {
        console.log('User created successfully!');
    // After successfully updating the profile
	console.log(finishCallback);
	finishCallback()
      })
      .catch(error => {
        console.error('There was an error creating the user!', error);
        alert('Failed to create user.' + error);
      });
  };

  return (
    <div className="create-user-container">
    <Header title="Profile Update" /> {/* Include the Header */}
      <form onSubmit={handleSubmit} className="create-user-form">
        <div className="form-group">
          <label>Username:</label>
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Name:</label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Profile Image:</label>
          <input type="file" name="image" accept="image/*" onChange={handleProfileImageChange} required />
        </div>
        <div className="form-group">
          <label>Date of Birth:</label>
          <input type="date" value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} />
        </div>
        <div className="form-group">
          <label>Gender:</label>
          <select value={gender} onChange={(e) => setGender(e.target.value)} required>
            <option value="">Select gender</option>
            <option value="man">Man</option>
            <option value="woman">Woman</option>
            <option value="other">Other</option>
            <option value="prefer_not_to_say">Prefer not to say</option>
          </select>
        </div>
        <button type="submit" className="submit-button">Create User</button>
      </form>
    </div>
  );
};

export default ProfileUpdate;

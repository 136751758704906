import React, {useEffect, useRef, useState} from 'react';

const SpriteAnimation = ({
                             bodySheet,
                             hatSheet,
                             magicSheet,
                             pantsSheet,
                             shoesSheet,
                             sunglassesSheet,
                             topSheet,
                             frameWidth,    // Width of each frame
                             frameHeight,   // Height of each frame
                             frameCount,    // Number of frames in the sprite sheet
                             frameRate,     // Frames per second
                             action,        // Action type (row in the sprite sheet)
                             loop = true,   // Should the animation loop
                         }) => {
    const [frameIndex, setFrameIndex] = useState(0);
    const canvasRef = useRef(null);

    // bodySheet
    // hatSheet
    // magicSheet
    // pantsSheet
    // shoesSheet
    // sunglassesSheet
    // topSheet

    useEffect(() => {
        const ctx = canvasRef.current.getContext('2d');

        const sheets = [bodySheet, hatSheet, magicSheet, pantsSheet, shoesSheet, sunglassesSheet, topSheet];

        const images = sheets.map((item, index) => {
            // console.log(`Index: ${index}, Item: ${item}`);
            const image = new Image();
            image.src = sheets[index];
            return image;
        });

        let imageLoaded = sheets.map(() => false)

        const renderWhenAllReady = (image) => () => {

            const index = images.findIndex(item => item === image);
            imageLoaded[index] = true

            if (imageLoaded.every(value => value === true)) {
                renderFrame()
            }
        }

        const renderFrame = () => {
            // Clear the canvas before drawing the new frame
            ctx.clearRect(0, 0, frameWidth, frameHeight);

            // Calculate the x and y position of the current frame in the sprite sheet
            const xPos = frameIndex * frameWidth;
            const yPos = action * frameHeight; // action determines the row in the sprite sheet

            // Draw the current frame on the canvas
            images.map(img =>
                ctx.drawImage(img, xPos, yPos, frameWidth, frameHeight,  // Source rectangle (sprite sheet)
                    0, 0, frameWidth, frameHeight        // Destination rectangle (canvas)
                )
            )
        };

        const intervalId = setInterval(() => {
            setFrameIndex((prevIndex) => {
                const nextIndex = (prevIndex + 1) % frameCount;
                if (!loop && nextIndex === 0) {
                    clearInterval(intervalId);
                }
                return nextIndex;
            });
        }, 1000 / frameRate);

        // Load image and render the first frame when the image is loaded
        images.map(img => img.onload = renderWhenAllReady(img));

        // Render frame whenever frameIndex or action changes
        renderFrame();

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, [bodySheet, hatSheet, magicSheet, frameWidth, frameHeight, frameCount, frameRate, frameIndex, loop, action]);

    return <canvas ref={canvasRef} width={frameWidth} height={frameHeight}/>;
};

export default SpriteAnimation;

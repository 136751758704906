import React, { useState, useEffect } from 'react';
import api from './api'; // Import your API utility
import './BraceletFoundry.css'; // Import your CSS

const BraceletFoundry = () => {
  const [bracelets, setBracelets] = useState([]); // Ensure it's initialized as an empty array
  const [numToCreate, setNumToCreate] = useState(1);
  const [selectedBracelets, setSelectedBracelets] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // State for search query

  useEffect(() => {
    fetchBracelets();
  }, []);

  const fetchBracelets = async () => {
    try {
      const response = await api.get('/api/bracelet/list/all');
      setBracelets(response.data.bracelets || []); // Default to an empty array if data is undefined
    } catch (error) {
      console.error('Error fetching bracelets:', error);
      setBracelets([]); // Set an empty array on error
    }
  };

  const handleCreateBracelets = async () => {
    try {
      await api.post('/api/bracelet/create', { count: numToCreate });
      fetchBracelets();
    } catch (error) {
      console.error('Error creating bracelets:', error);
    }
  };

  const handleMarkAsSold = async () => {
    // API call to mark selected bracelets as sold
    try {
      await api.post('/api/bracelet/mark/sold', { ids: selectedBracelets });
      fetchBracelets();
    } catch (error) {
      console.error('Error marking bracelets as sold:', error);
    }
  };

  const handleMarkAsNotSold = async () => {
    // API call to mark selected bracelets as not sold
    try {
      await api.post('/api/bracelet/mark/not_sold', { ids: selectedBracelets });
      fetchBracelets();
    } catch (error) {
      console.error('Error marking bracelets as not sold:', error);
    }
  };

  const handleDelete = async () => {
    // API call to mark selected bracelets as deleted
    try {
      await api.post('/api/bracelet/mark/delete', { ids: selectedBracelets });
      fetchBracelets();
    } catch (error) {
      console.error('Error deleting bracelets:', error);
    }
  };

  const toggleSelectBracelet = (id) => {
    setSelectedBracelets((prev) =>
      prev.includes(id)
        ? prev.filter((braceletId) => braceletId !== id)
        : [...prev, id]
    );
  };

  // Filter bracelets based on the search query
  const filteredBracelets = bracelets.filter((bracelet) =>
    bracelet.bracelet.id.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="bracelet-foundry">
      <h2>Bracelet Foundry</h2>
      
      <div className="create-bracelet-section">
        <input
          type="number"
          min="1"
          max="10"
          value={numToCreate}
          onChange={(e) => setNumToCreate(e.target.value)}
        />
        <button className="btn-create" onClick={handleCreateBracelets}>
          Create Bracelets
        </button>
      </div>

      <div className="search-section">
        <input
          type="text"
          placeholder="Search bracelets by ID..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-input"
        />
      </div>
      
      <div className="bracelet-management-buttons">
        <button
          className="btn-mark-sold"
          onClick={handleMarkAsSold}
          disabled={selectedBracelets.length === 0}
        >
          Mark as Sold
        </button>
        <button
          className="btn-mark-not-sold"
          onClick={handleMarkAsNotSold}
          disabled={selectedBracelets.length === 0}
        >
          Mark as Not Sold
        </button>
        <button
          className="btn-delete"
          onClick={handleDelete}
          disabled={selectedBracelets.length === 0}
        >
          Delete
        </button>
      </div>

      <table className="bracelet-table">
        <thead>
          <tr>
            <th>Select</th>
            <th>ID</th>
            <th>Created At</th>
            <th>Status</th>
            <th>Room Id</th>
            <th>Room Title</th>
            <th>Room Name</th>

          </tr>
        </thead>
        <tbody>
          {filteredBracelets.length > 0 ? (
            filteredBracelets.map((bracelet) => (
              <tr key={bracelet.bracelet.id}
                  onClick={() => toggleSelectBracelet(bracelet.bracelet.id)}
		    >
		<td className={`checkbox-indicator ${selectedBracelets.includes(bracelet.bracelet.id) ? 'checked' : 'unchecked'}`}>
  			{selectedBracelets.includes(bracelet.bracelet.id) ? '✔' : '✗'}
		</td>
                <td>{bracelet.bracelet.id}</td>
                <td>{bracelet.bracelet.created_at}</td>
                <td>{bracelet.bracelet.status}</td>
                <td>{(bracelet.room)? bracelet.room.id : '-'}</td>
                <td>{(bracelet.room)? bracelet.room.title : '-'}</td>
                <td>{(bracelet.room)? bracelet.room.name : '-'}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No bracelets found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BraceletFoundry;


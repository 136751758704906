import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css'; // Import the CSS file for styling

const Header = ({ title }) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <div className="header-container">
      <button className="back-button" onClick={handleBackClick}>
        &larr; Back
      </button>
      <h1 className="header-title">{title}</h1>
    </div>
  );
};

export default Header;


// src/Gotu.js
import React from 'react';
import { useParams } from 'react-router-dom';

function Gotu() {
  const { id } = useParams();

  return (
    <div>
      <h1>Gotu Page</h1>
      <p>The ID is: {id}</p>
    </div>
  );
}

export default Gotu;

import { useState, useEffect } from 'react';
import api from './api'; // Adjust the path to your API utility file

export const useMiniMe = (userId) => {
  const [miniMeData, setMiniMeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOutfitSelection = async () => {
      try {
        const response = await api.get('/api/outfit/user', {
          params: { user_id: userId },
        });

        console.log('Minime fetch response ', response);
        if (response.data) {
          setMiniMeData({
            body: response.data.body_url,
            hat: response.data.hat_url,
            top: response.data.top_url,
            pants: response.data.pants_url,
          });
        }
      } catch (err) {
        console.error('Error fetching outfit selection:', err);
        setError('Failed to load mini-me data.');
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchOutfitSelection();
    } else{
	    console.log("user id no exist for minime")
    }
  }, [userId]);

  return { miniMeData, loading, error };
};


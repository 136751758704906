import React from 'react';
import { useMiniMe } from './useMiniMe';
import './MiniMe.css'; // Import the CSS file

const MiniMeComponent = ({ userId }) => {
  const { miniMeData, loading, error } = useMiniMe(userId);

  if (loading) {
    return <div>{userId } Loading your Mini Me...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="mini-me-container">
      {miniMeData && (
        <div className="character-view">
          <img src={miniMeData.body} alt="Body" className="character-base" />
          <img src={miniMeData.pants} alt="Pants" className="character-pants" />
          <img src={miniMeData.top} alt="Top" className="character-top" />
          <img src={miniMeData.hat} alt="Hat" className="character-hat" />
        </div>
      )}
    </div>
  );
};

export default MiniMeComponent;


import React, { useState, useEffect } from 'react';
import api from './api';
import { useNavigate } from 'react-router-dom';
import './RoomList.css'; // Import the CSS file

const RoomList = ({ user, fetchType }) => {
  const [rooms, setRooms] = useState([]);
  const navigate = useNavigate();

  const handleRoomClick = (roomId) => {
    navigate(`/room/${roomId}`);
  };

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        let response;
        if (fetchType === 'my_rooms') {
          response = await api.get('/api/room/my_rooms');
        } else {
          response = await api.get('/api/room/all_rooms');
        }
        setRooms(response.data);
      } catch (error) {
        console.error('There was an error fetching rooms!', error);
      }
    };

    fetchRooms();
  }, [fetchType]);

  if(rooms) {
    return (
        <div className="room-list-container">
          <h2 className="room-list-title">{fetchType === 'my_rooms' ? 'My Rooms' : 'All Rooms'}</h2>
          <ul className="room-list">
            {rooms.map(room => (
                <li key={room.id} className="room-list-item" onClick={() => handleRoomClick(room.id)}>
                  <span className="room-id">{room.id}</span> : <span className="room-title">{room.title}</span>
                </li>
            ))}
          </ul>
        </div>
    );
  } else {
    return (<div>No rooms found</div>)
  }
};

export default RoomList;


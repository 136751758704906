import React, { useState, useEffect } from 'react';
import api from './api'; // Adjust the path as necessary
import { useParams, useNavigate } from 'react-router-dom';
import './Room.css'; // Import the CSS file
import Header from './Header'; // Import the Header component

const Room = () => {
  const { roomId } = useParams();
  const [roomDetails, setRoomDetails] = useState(null);

  const navigate = useNavigate();

  const handleRoomClick = (roomId) => {
    console.log("Room click " + roomId);
    navigate(`/creation/${roomId}`);
  };

  useEffect(() => {
    const fetchRoomDetails = async () => {
      try {
        const response = await api.get('/api/room/room_details', {
          params: { room_id: roomId },
        });
        setRoomDetails(response.data); // Correctly set the room details
        console.log("room detail", response.data);
      } catch (error) {
        console.error('There was an error fetching room details!', error);
      }
    };

    fetchRoomDetails(); // Call the function to fetch room details
  }, [roomId]);

  if (!roomDetails) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="room-container">
      <Header title={roomDetails.room_title} /> {/* Include the Header */}
      <h2 className="room-title">{roomDetails.room_title}</h2>

      <p className="create-post" onClick={() => handleRoomClick(roomId)}>
        Create Post
      </p>

      <ul className="posts-list">
        {roomDetails.posts.map((post, index) => (
          <li key={index} className="post-item">
            <div className="post-author">
              <img
                src={post.author.profile_image_url}
                alt={post.author.username}
                className="author-profile-image"
              />
              <span className="author-username">{post.author.username}</span>
            </div>
            <img src={post.url} alt={`Post ${index}`} className="post-image" />
	    <div className="post-description">
		<p className="post-description-text">{post.description}</p>	
	    </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Room;

